<template>
  <div>
    <TeamView/>
  </div>
</template>

<script>
import TeamView from "../components/team/TeamView.vue";

export default {
  name: "Team",
  components: {
    TeamView,
  },
};
</script>