<template>
  <div>
    <BlogView/>
  </div>
</template>

<script>
import BlogView from "../components/blog/BlogView.vue";

export default {
  name: "Blog",
  components: {
    BlogView,
  },
};
</script>